import { defineStore } from 'pinia'
import OrdenService from '../services/orden.service'
const _OrdenService = new OrdenService()

export const entregasStore = defineStore('entregasStore', {
  state: () => ({
    _orden: {
      Cliente: {},
      Bodega: {},
      DocumentLines: [],
      Paciente: {},
      MotAuto: {},
      DiagPpal: {
        CdCie: '',
        DesCie: ''
      }
    },
    _medicamentos_eliminados: [],
    _medicamentos_entregar: []
  }),
  getters: {
    getOrden: (state) => state._orden,
    eliminarMedicamento: (state) => state._medicamentos_eliminados,
    medicamentosEntregar: (state) => state._medicamentos_entregar
  },
  actions: {
    findOrden (id) {
      return new Promise((resolve, reject) => {
        _OrdenService.find(id).then(({ data }) => {
          this._orden = data
          this._medicamentos_entregar = data.DocumentLines
          resolve(data)
        })
      })
    }
  }
})
