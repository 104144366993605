import { defineStore } from 'pinia'
import BodegasService from '../services/bodegas.service'
const _BodegasService = new BodegasService()

export const useBodegasStore = defineStore('bodegasStore', {
  state: () => ({
    listado_bodegas: [],
    bodega_seleccionada: ''
  }),
  getters: {
    _listado_bodegas: state => state.listado_bodegas,
    _bodega_seleccionada: state => state.bodega_seleccionada
  },
  actions: {
    obtenerListadoBodegas () {
      _BodegasService.listarBodegasTodas().then(({ data }) => {
        const array = []
        for (const i of data) {
          array.push({
            ...i,
            name: i.WhsCode + ' - ' + i.WhsName
          })
        }
        this.listado_bodegas = array
      })
    }
  }
})
