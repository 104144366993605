import { defineStore } from 'pinia'
import DiagnosticosService from '../services/diagnosticos.service'
const _DiagnosticosService = new DiagnosticosService()

export const useDiagnosticosStore = defineStore('diagnosticosStore', {
  state: () => ({
    listado_diagnosticos: [],
    diagnostico_ppal: ''
  }),
  getters: {
    _listado_diagnosticos: state => state.listado_diagnosticos,
    _diagnostico_ppal: state => state.diagnostico_ppal
  },
  actions: {
    obtenerDiagnosticos (name) {
      _DiagnosticosService.listarDiagnosticos(name).then(({ data }) => {
        const array = []
        for (const i of data) {
          array.push({
            ...i,
            name: i.CdCie + ' - ' + i.DesCie
          })
        }
        this.listado_diagnosticos = array
      })
    }
  }
})
