import { defineStore } from 'pinia'
import ClientesService from '../services/config-sap.service'
import ConfigService from '../services/config_cols_tabla.service'
const _ClientesService = new ClientesService()
const _ConfigService = new ConfigService()

export const useConfigGlobal = defineStore('configGlobal', {
  state: () => ({
    configuracion_global: {},
    columnas_tabla: [
      {
          ColName: 'Id',
          IsRequired: false,
          nombre_tabla: 'Id',
          ordenamiento: 1,
          guardar: false,
          campo_oculto: null,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'ItemName',
          IsRequired: false,
          nombre_tabla: 'Artículo',
          ordenamiento: 2,
          guardar: false,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'Quantity',
          IsRequired: true,
          nombre_tabla: 'Cant.',
          ordenamiento: 3,
          guardar: true,
          campo_oculto: false,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'UseBaseUnits',
          IsRequired: false,
          nombre_tabla: 'Und. Med.',
          ordenamiento: 4,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'UnitPrice',
          IsRequired: true,
          nombre_tabla: 'Precio',
          ordenamiento: 5,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'PriceTotal',
          IsRequired: false,
          nombre_tabla: 'Precio Total',
          ordenamiento: 6,
          guardar: false,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'WarehouseCode',
          IsRequired: true,
          nombre_tabla: 'Bodega',
          ordenamiento: 7,
          guardar: true,
          campo_oculto: true,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_Sta_Pen',
          IsRequired: false,
          nombre_tabla: 'Pendiente',
          ordenamiento: 8,
          guardar: true,
          campo_oculto: true,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_Req_Mar',
          IsRequired: false,
          nombre_tabla: 'Requiere Marca',
          ordenamiento: 9,
          guardar: true,
          campo_oculto: true,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_SeguiEntrega',
          IsRequired: false,
          nombre_tabla: 'Seg. Entregas',
          ordenamiento: 10,
          guardar: true,
          campo_oculto: false,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_NumEntregas',
          IsRequired: false,
          nombre_tabla: 'No. Entregas',
          ordenamiento: 11,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_CtdPrescrita',
          IsRequired: false,
          nombre_tabla: 'Cant. Prescrita',
          ordenamiento: 12,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_FecPrescrip',
          IsRequired: false,
          nombre_tabla: 'Fecha Prescripción',
          ordenamiento: 13,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_Frecuencia',
          IsRequired: false,
          nombre_tabla: 'Frecuencia',
          ordenamiento: 14,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_DuraTratami',
          IsRequired: false,
          nombre_tabla: 'D. tratamiento',
          ordenamiento: 15,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_RegMed',
          IsRequired: false,
          nombre_tabla: 'Registro Médico/ Nombre Médico',
          ordenamiento: 16,
          guardar: true,
          campo_oculto: false,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_IPSPrest',
          IsRequired: false,
          nombre_tabla: 'IPS Prestadora Servicio',
          ordenamiento: 17,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
        ColName: 'U_PHR_NumAutoriza',
        IsRequired: true,
        nombre_tabla: 'No. Auto.',
        ordenamiento: 18,
        guardar: true,
        campo_oculto: true,
        updatedBy: null
      },
      {
        ColName: 'U_PHR_FecAutoriza',
        IsRequired: true,
        nombre_tabla: 'Fec. Auto.',
        ordenamiento: 19,
        guardar: true,
        campo_oculto: null,
        updatedBy: null
      },
      {
        ColName: 'U_PHR_Siniestro',
        IsRequired: true,
        nombre_tabla: 'Siniestro',
        ordenamiento: 20,
        guardar: true,
        campo_oculto: true,
        updatedBy: null
      },
      {
        ColName: 'ItemCode',
        IsRequired: true,
        nombre_tabla: 'Articulo',
        ordenamiento: 21,
        guardar: true,
        campo_oculto: null,
        updatedBy: null
      }
    ],
    columnas_tabla_inicial: [
      {
          ColName: 'Id',
          IsRequired: false,
          nombre_tabla: 'Id',
          ordenamiento: 1,
          guardar: false,
          campo_oculto: null,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'ItemName',
          IsRequired: false,
          nombre_tabla: 'Artículo',
          ordenamiento: 2,
          guardar: false,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'Quantity',
          IsRequired: true,
          nombre_tabla: 'Cant.',
          ordenamiento: 3,
          guardar: true,
          campo_oculto: false,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'UseBaseUnits',
          IsRequired: false,
          nombre_tabla: 'Und. Med.',
          ordenamiento: 4,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'UnitPrice',
          IsRequired: true,
          nombre_tabla: 'Precio',
          ordenamiento: 5,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'PriceTotal',
          IsRequired: false,
          nombre_tabla: 'Precio Total',
          ordenamiento: 6,
          guardar: false,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'WarehouseCode',
          IsRequired: true,
          nombre_tabla: 'Bodega',
          ordenamiento: 7,
          guardar: true,
          campo_oculto: true,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_Sta_Pen',
          IsRequired: false,
          nombre_tabla: 'Pendiente',
          ordenamiento: 8,
          guardar: true,
          campo_oculto: true,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_Req_Mar',
          IsRequired: false,
          nombre_tabla: 'Requiere Marca',
          ordenamiento: 9,
          guardar: true,
          campo_oculto: true,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_SeguiEntrega',
          IsRequired: false,
          nombre_tabla: 'Seg. Entregas',
          ordenamiento: 10,
          guardar: true,
          campo_oculto: false,
          updatedBy: 'Daily-Tech'
      },
      {
          ColName: 'U_PHR_NumEntregas',
          IsRequired: false,
          nombre_tabla: 'No. Entregas',
          ordenamiento: 11,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_CtdPrescrita',
          IsRequired: false,
          nombre_tabla: 'Cant. Prescrita',
          ordenamiento: 12,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_FecPrescrip',
          IsRequired: false,
          nombre_tabla: 'Fecha Prescripción',
          ordenamiento: 13,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_Frecuencia',
          IsRequired: false,
          nombre_tabla: 'Frecuencia',
          ordenamiento: 14,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_DuraTratami',
          IsRequired: false,
          nombre_tabla: 'D. tratamiento',
          ordenamiento: 15,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_RegMed',
          IsRequired: false,
          nombre_tabla: 'Registro Médico/ Nombre Médico',
          ordenamiento: 16,
          guardar: true,
          campo_oculto: false,
          updatedBy: null
      },
      {
          ColName: 'U_PHR_IPSPrest',
          IsRequired: false,
          nombre_tabla: 'IPS Prestadora Servicio',
          ordenamiento: 17,
          guardar: true,
          campo_oculto: null,
          updatedBy: null
      },
      {
        ColName: 'U_PHR_NumAutoriza',
        IsRequired: true,
        nombre_tabla: 'No. Auto.',
        ordenamiento: 18,
        guardar: true,
        campo_oculto: true,
        updatedBy: null
      },
      {
        ColName: 'U_PHR_FecAutoriza',
        IsRequired: true,
        nombre_tabla: 'Fec. Auto.',
        ordenamiento: 19,
        guardar: true,
        campo_oculto: null,
        updatedBy: null
      },
      {
        ColName: 'U_PHR_Siniestro',
        IsRequired: true,
        nombre_tabla: 'Siniestro',
        ordenamiento: 20,
        guardar: true,
        campo_oculto: true,
        updatedBy: null
      },
      {
        ColName: 'ItemCode',
        IsRequired: true,
        nombre_tabla: 'Articulo',
        ordenamiento: 21,
        guardar: true,
        campo_oculto: null,
        updatedBy: null
      }
    ]
  }),
  getters: {
    _configuracion_global: state => state.configuracion_global,
    _columnas_tabla: state => state.columnas_tabla,
    _columnas_tabla_inicial: state => state.columnas_tabla_inicial
  },
  actions: {
    obtenerConfiguracionGlobal () {
      _ClientesService.get().then(({ data }) => {
        this.configuracion_global = data
        return Promise.resolve(data)
      })
    },
    obtenerColumnasTabla (params) {
      _ConfigService.listarColumnasTabla(params).then(({ data }) => {
        this.columnas_tabla = data
      })
    },
    limpiarColumnasTabla () {
      this.columnas_tabla = this.columnas_tabla_inicial
    }
  }
})
