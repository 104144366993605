import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PAGOS_RECIBIDOS

export default class SyncPagosService {
  sincronizarPagosRecibidos (payload) {
    return http.post(`${baseUrl}/pagos/aprobar`, payload, {
      headers: {
        loading: true
      }
    })
  }

  find (ov) {
    return http.get(`${baseUrl}/pagos/find/${ov}`, {
      headers: {
        loading: true
      }
    })
  }
}
