import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class ClientesService {
  get () {
    return http.get(`${baseUrl}/config-sap/`, {
      headers: {
        loading: true
      }
    })
  }

  update (data) {
    return http.put(`${baseUrl}/config-sap/`, data, {
      headers: {
        loading: true
      }
    })
  }
}
